// collection of the base functionality needed to manage cases.
YUI.add('case-manager', function(Y) {
    "use strict";

    var SLang = Y.smst.Lang;

    var CaseManager = Y.Base.create('CaseManager', Y.App, [Y.AppHeader,Y.AppOverlays], {

        headers: {
            standard  : Y.CaseManagerHeaderView
        },

        views: {
            tiles : {type:'CaseManagerTilesView'}
            // this could be extended to a table and or list view
        },

        // we are using some overlays to perform certain tasks. These are configured here.
        // All of them will be created only if they are requested.
        overlays : {
            create : {
                header:"Neuen Steuerfall anlegen",
                footer:'<button type="button" id="new-case" class="btn btn-hilited">Neuen Steuerfall anlegen</button>',
                template: 'case-manager-overlay-new',
                events : {
                    '#new-case' : { click : '_handleCreate' },
                    '.yui3-widget' : { keypress : '_handleCreate' }
                },
                mobileNode: '#cases-manager-tiles'
            },
            'delete' : {
                header:"Steuerfall löschen",
                footer:'<button type="button" id="ui-delete-case" class="btn btn-destructive">Steuerfall löschen</button><button type="button" class="btn btn-hilited" id="ui-delete-abort">Abbrechen</button>',
                template: 'case-manager-overlay-delete',
                events : {
                    '#ui-delete-case'  : { click : '_handleDelete' },
                    '#ui-delete-abort' : { click : '_abortDelete' },
                    '.yui3-widget'     : { keypress : '_abortDelete' }
                },
	            // don't add escape listeners and buttons
	            notEscapable : true,
	            // now we have to add the escape listeners manually
	            plugins : [
		            Y.Plugin.OverlayModal,
		            Y.gorilla.overlay.AnimPlugin,
		            Y.gorilla.widget.EscapeHidePlugin,
					Y.gorilla.widget.XButtonPlugin
				],
                mobileNode: '#cases-manager-tiles'
            },
            rename : {
                header:"Steuerfall umbenennen",
                footer:'<button type="button" id="rename-case" class="btn btn-hilited">Steuerfall umbenennen</button>',
                template: 'case-manager-overlay-rename',
                events : {
                    '#rename-case' : { click : '_handleRename' },
                    '.yui3-widget' : { keypress : '_handleRename' }
                },
                mobileNode: '#cases-manager-tiles'
            },
            importOverlay : {
                header:"Steuerfall importieren",
                footer:'<button type="button" id="import-case" class="btn btn-hilited">Auswählen</button>',
                template: 'case-manager-overlay-import',
                events : {
                    '#import-case' : { click : '_handleImport' },
                    '.yui3-widget' : { keypress : '_handleImport' }
                },
                mobileNode: '#cases-manager-tiles'
            },
            importLexoffice : {
                header:"Lexoffice-Steuerfall importieren",
                footer:'<button type="button" id="import-lexoffice-case" class="btn btn-hilited">Steuerfall anlegen</button>',
                template: 'case-manager-overlay-lexofficeimport',
                width: '850px',
                events : {
                    '#import-lexoffice-case' : { click : '_handleLexofficeImport' },
                    '.yui3-widget' : { keypress : '_handleLexofficeImport' }
                }
            },
            lock : {
                header:"Steuerfall mit Kennwort sperren",
                footer:'<button type="button" id="lock-case" class="btn btn-hilited">Kennwort setzen</button>',
                template: 'case-manager-overlay-lock',
                events : {
                    '#lock-case' : { click : '_handleLock' },
                    '.yui3-widget' : { keypress : '_handleLock' }
                },
                mobileNode: '#cases-manager-tiles'
            },
            unlock : {
                header:"Steuerfall entsperren",
                footer:'<button type="button" id="unlock-case" class="btn btn-hilited">Steuerfall entsperren</button>',
                template: 'case-manager-overlay-unlock',
                events : {
                    '#unlock-case' : { click : '_handleUnlock' },
                    '.yui3-widget' : { keypress : '_handleUnlock' }
                },
                mobileNode: '#cases-manager-tiles'
            },
            migrate : {
                header:"Steuerfall in aktuelles Jahr übertragen",
                footer:'<button type="button" id="migrate-case" class="btn btn-hilited">Steuerfall übertragen</button>',
                template: 'case-manager-overlay-migrate',
                events : {
                    '#migrate-case' : { click : '_handleCustomMigrate' },
                    '.yui3-widget' : { keypress : '_handleCustomMigrate' }
                },
                mobileNode: '#cases-manager-tiles'
            },
            password : {
                header:"Bitte geben Sie das Passwort für diesen Fall an",
                footer:'<button type="button" id="migrate-case-password" class="btn btn-hilited">Steuerfall übertragen</button>',
                template: 'case-manager-overlay-password',
                events : {
                    '#migrate-case-password' : { click : '_handleCustomMigratePassword' },
                    '.yui3-widget' : { keypress : '_handleCustomMigratePassword' }
                },
                mobileNode: '#cases-manager-tiles'
            },
            leave : {
                header:"Steuerfall in anderem Steuerjahr bearbeiten",
                footer:'<button type="button" id="ui-leave-app" class="btn btn-hilited">Zur Auswahl</button>',
                template: 'case-manager-overlay-leave',
                events : {
                    '#ui-leave-app' : { click : '_handleLeaveApp' },
                    '.yui3-widget' : { keypress : '_handleLeaveApp' }
                },
                mobileNode: '#cases-manager-tiles'
            },
            vast : {
                header: "Vorausgefüllte Steuererklärung",
                footer: '<button type="button" id="ui-open-vast" class="btn btn-orange">Daten abholen</button>',
                events : {
                    '#ui-open-vast' : { click : '_handleOpenVast' }
                },
                template: 'case-manager-overlay-vast',
                mobileNode: '#cases-manager-tiles'
            },
            belegeService : {
                header: "Hat das Finanzamt Rückfragen zu Deiner Steuererklärung?",
                footer: '<button type="button" id="ui-open-belege" class="btn btn-orange">Jetzt Belege nachreichen</button>',
                events : {
                    '#ui-open-belege' : { click : '_handleOpenBelegeService' }
                },
                template: 'case-manager-overlay-belege',
                mobileNode: '#cases-manager-tiles'
            },
            imported : {
                header: "Prima, das hat geklappt!",
                footer: '<button type="button" id="ui-open-imported-case" class="btn btn-orange btn-pulse-hilited">Steuererklärung prüfen</button>',
                events : {
                    '#ui-open-imported-case' : { click : '_handleOpenImportedCase' }
                },
                template: 'case-manager-overlay-imported',
                mobileNode: '#cases-manager-tiles'
            }
        },

        _registeredEventHandler : [],

        // we need to keep track of open overlays.
        _waitingOverlayHandler : {},

        // --- housekeeping --------------------------------------------------------------------------------------

        initializer : function() {
            var that = this,
                caseList = that.get('caseList'),
                legacyList = that.get('caseLegacyList'),
		        sidebar;


            // we have to get hold of the configuration of this session...
            Y.once('smartsteuer:ready', function(config){this.set('config', config)}, this);

            // first activate the default header and view.
            that.showHeader('standard');
            that.showView('tiles', {
                caseList:caseList,
                legacyList:legacyList
            });

            // after a new case was added to our case list, we are going to open it for editing.
            caseList.after('add', Y.rbind( this._handleAddedCase, this) );
            // after a model was saved have to clean up the waiting state.
            caseList.after('*:save', Y.rbind( this._handleCaseSaved, this));

            // listener for all kind of event which signal to create a new case.
            this._registeredEventHandler.push(this.on('*:vastCase', function(e) {console.log(1); this.fire('alert', {msg: "duh"})}));
            this._registeredEventHandler.push(this.on('*:newCaseBlank', this._onNewCaseBlank));
            this._registeredEventHandler.push(this.on('*:newCaseImport', this._onImportCase ));
            this._registeredEventHandler.push(this.on('*:importQuicksteuerCase', this._onQSImport));
            this._registeredEventHandler.push(Y.one('body').delegate('click', this._handleImportChoiceChange, 'input[name=ui-import-file-type]'));
            // and of cause listen to all those other management events
            this._registeredEventHandler.push(this.on('*:openCase',  this._onOpenCase));
            this._registeredEventHandler.push(this.on('*:renameCase', this._onRenameCase));
            this._registeredEventHandler.push(this.on('*:deleteCase', this._onDeleteCase));
            this._registeredEventHandler.push(this.on('*:exportCase',  this._onExportCase));
            this._registeredEventHandler.push(this.on('*:lockCase', this._onLockCase));
            this._registeredEventHandler.push(this.on('*:unlockCase', this._onUnlockCase));
            this._registeredEventHandler.push(this.on('*:vastCase', function() { console.log('vastCase caught'); this._onVastCase}));

	        // actions on legacy cases
            this._registeredEventHandler.push(this.on('*:migrateCase', this._onMigrateCase));
            this._registeredEventHandler.push(this.on('*:editLegacyCase', this._onEditLegacyCase));

            // here we are subscribing to an event our parent application will fire every time the active case
            // changes. This is important for as changes a good that we marked that case 'waiting'. When it became
            // the active case it's no longer waiting...
            this._registeredEventHandler.push(Y.after('SMST:activeCaseChange',
                                              Y.rbind(this._afterActiveCaseChanged,this)));

            // publish all events this manager is able to throw.
            this.publish('newCase', {preventable:false});

            this._registeredEventHandler.push(this.onceAfter('ready', this._initVue));
            this._registeredEventHandler.push(
                Y.delegate(
                    'click',
                    function(e) {this.showOverlay('vast', {});},
                    '#body',
                    '.ui-case-vast',
                    this
                )
            );
            this._registeredEventHandler.push(
                Y.delegate(
                    'click',
                    function(e) {this.showOverlay('belegeService', {});},
                    '#body',
                    '.ui-case-belege',
                    this
                )
            );
        },

        render : function() {
            var that = this,
                caseList = that.get('caseList'),
                legacyList = that.get('caseLegacyList');

            that.constructor.superclass.render.apply(that, arguments);

            legacyList.load();

            caseList.load(function() {
                var cases = [];
                var index = 0;
                var autoOpen = null;

                while(index < caseList.size()) {
                    var item = {};
                    item.name = caseList.item(index).get('name');
                    item.id = caseList.item(index).get('id');
                    cases.push(item);

                    if(caseList.item(index).get('autoOpen')) {
                        autoOpen = item.id;
                    }

                    index++;
                }

                if(autoOpen) {
                    //TODO: should be casecontroller redirect, but currently needs casecontroller/list to migrate case
                    //TODO: only autoopen for first year customer flow
                    /*
                    if(Y.one('.ui-case-'+autoOpen)) {
                        Y.one('.ui-case-'+autoOpen).simulate('click');
                    }
                    */
                }
                else {
                    if(that.get('config').lexofficeImportPresent) {
                        that.showOverlay('importLexoffice', {'cases': cases}, {'cases': cases});
                    }
                }

            });

            return that;
        },

        _initVue : function() {
            if(SMST_config.onseClientName != 'smst') {
                //not showing this for other clients
                return;
            }

            Y.one('#cases-manager-header').insert(
                '<div class="cases-manager-sidebar" id="cases-manager-sidebar"><div id="vue-sidebar"></div></div>',
                'after'
            );

            this.vueApp = onse.default.makeCaseListApp({
                caseListProps : {
                    vastUrl: this.get('config').vastURL,
                    beleguanUrl: this.get('config').beleguanURL
                }
            });

            this.vueApp.app.$mount('#vue-sidebar')
        },

        destructor : function() {
            SLang.detachEventListener(this._registeredEventHandler);

            if(this.vueApp) {
                this.vueApp.app.$destroy();
            }
        },


        // --- event handler - creating cases -----------------------------------------------------------------------

        _handleAddedCase : function(e){
            var caze = e.model;
            // it's new - we want it active.
            caze.set('active', true);
            this.fire('newCase', {'case':caze, 'section':'interview'})
        },

        _onNewCaseBlank : function( ) {
            var overlay = this.showOverlay('create');
            // select the default value for easy editing.
            Y.Node.getDOMNode( overlay.get('contentBox').one('#caseName') ).select();
        },

        _handleCreate : function(e,payload){
            var overlay = payload.overlay,
                box = overlay.get('boundingBox'),
                name = box.one('#caseName').get('value');

            // if this was a keypress only do stuff if it was the enter key.
            if( e.keyCode && 13 !== e.keyCode ) {
                return;
            }
            e.halt();

            if( this._isInvalidName(name,overlay)) {
                return;
            }
            overlay.hide();
            this.createCase(name);
        },

        insertMockCase:function (caze) {
            // create the mock
            var mockTemplate = Y.HandlebarsTemplates.lookup('case-manager-tile'),
                index = 0,
                list = this.get('caseList'),
                name = caze.get('name'),
		        belowOrSame = true,
		        it;
            // now find the index for this mock.
	        while( index < list.size() ) {
		        it = list.item(index);
		        if( name < it.get('name')) {
			        break;
		        }
			    index++;
	        }
            // finally added into the DOM
            Y.one('#cases-manager-caselist').insert(mockTemplate(caze.toJSON()), index );
        },

        createCase : function(name) {
            var list = this.get('caseList'),
                caze = new Y.Case({name:name, type:'EST', waiting:true});

            // before we are actually creating a new entry we are bringing in a placeholder to shorten the time the
            // user has to wait for the reaction.
            this.insertMockCase(caze);
            list.create(caze, {timeout:1000*60*3}, function(err, res){
                // TODO: handle errors here.
                if(err) {
                    Y.error("Error while creating new case.");
                }
            });
        },

        // --- common actions while editing a case... -------------------------------------------------------------

        // will show the action indicator on any case action.
        _indicateWaiting : function(caze) {
            caze.setAttrs({
                'waiting': true,
                'active' : true
            });
        },

        _clearWaitingState : function(caze){
            caze.set('waiting',false);
        },

        _cleanOverlayListener:function (name) {
            if(this._waitingOverlayHandler[name] ) {
                this._waitingOverlayHandler[name].detach();
                delete this._waitingOverlayHandler[name];
            }
        },

        _setUpOverlayListener:function (overlay, name, caze) {
            this._waitingOverlayHandler[name] =
                    overlay.onceAfter('visibleChange', Y.bind(this._clearWaitingState, this, caze));
        },

        _handleCaseSaved : function(e) {
            var caze = e.target,
                cazeList = e.currentTarget;

            this._clearWaitingState(caze);
            // when a case got a new name the list has to be resorted.
            cazeList.sort();
        },

        // --- renaming a case... ---------------------------------------------------------------------------------

        _onRenameCase : function(e) {
            var overlay, input,
                caze = e['case'];

            this._indicateWaiting(caze);

            overlay = this.showOverlay('rename', {'case':caze});
            // if the overlay gets closed without taking any action (e.g. the user hit esc or cancel)
            // we have to clean up the waiting state...
            this._setUpOverlayListener(overlay,'rename', caze);

            // select the default value for easy editing.
            input = overlay.get('contentBox').one('#caseName');
            input.set('value', caze.get('name'));
            Y.Node.getDOMNode( input ).select();
        },

        _handleRename : function(e,payload){
            var overlay = payload.overlay,
                box = overlay.get('boundingBox'),
                name = box.one('#caseName').get('value');

            // if this was a keypress only do stuff if it was the enter key.
            if( e.keyCode && 13 !== e.keyCode ) {
                return;
            }
            e.halt();

            if(this._isInvalidName(name,overlay)) {
                return;
            }
            // clean the extra overlay listener.
            this._cleanOverlayListener('rename');
            overlay.hide();
            this.renameCase(name, payload['case']);
        },

        renameCase : function(name, caze) {
            caze.set('name',name);
            caze.save();
        },



        // --- opening a case... ---------------------------------------------------------------------------------

        // not very much to do here.
        // The global app will handle the opening of the case.
        // The only thing we can do is to indicate this action.
        _onOpenCase : function(e) {
            this._indicateWaiting(e['case']); // that's all.
        },

        _handleOpenImportedCase : function(e, payload) {
            payload.overlay.hide();
            window.location.href = "/app#/case/"+payload.id+"/interview";
        },

        // once the case was opened and activated it is no longer in a waiting state.
        _afterActiveCaseChanged : function(e) {
            // but it is not guaranteed that this case is already in one of our lists. Therefor we simply disable
            // all the loading.
            this.get('caseList').invoke('set', 'waiting', false);

	        if(e.newVal) {
		        this.get('caseList').each(function(caze) {
			        if(caze.get('id') != e.newVal.get('id')) {
				        if(caze.get('wasLocked')) {
					        caze.setAttrs({
						        'wasLocked': false,
						        'locked' : true
					        });
				        }
			        }
		        });
	        }
        },

        // --- deleting a case... ---------------------------------------------------------------------------------

        _onDeleteCase : function(e) {
            var that = this,
                caze = e['case'],
		        overlay;

	        that._indicateWaiting(caze);
	        overlay = that.showOverlay('delete', {'case':caze});
	        that._setUpOverlayListener(overlay,'delete', caze);
        },

	    _abortDelete : function(e, payload) {
		    if( e.keyCode && 13 !== e.keyCode ) {
			    return;
		    }
		    payload.overlay.hide();
	    },

	    _handleDelete : function(e, payload) {
		    e.halt();

		    // this will do the dirty stuff
		    payload['case'].destroy({'delete':true});
		    payload.overlay.hide();
	    },

        //--- import a case... ---------------------------------------------------------------------------------------

        _onQSImport : function(e) {
            this._onImportCase(e, true);
        },

        _onImportCase : function(e, isQuicksteuerView) {
            var config = this.get('config'),
                overlay = this.showOverlay(
                    'importOverlay',
                    {},
                    {
                        'tokenName':config.tName,
                        'token':config.t,
                        showElfoImport: config.showElfoImport
                    }
                );

            //TODO: maybe reimplement if we really want that. But splitting qs/tm anyway?
            /*
            if(isQuicksteuerView) {
                Y.one("#import-file-type").set("selectedIndex", 1);
            }
             */

            // select the default value for easy editing.
            Y.Node.getDOMNode( overlay.get('contentBox').one('#importCaseName') ).select();
        },

        _handleLexofficeImport : function(e, payload) {
            e.halt();

            var instance = this,
                ov = payload.overlay;

            //instance.insertMockCase(new Y.Case({waiting:true,name:name}));
            instance.setWaiting(ov);

            var type = Y.one('#work-type').get('value')+"_"+Y.one('#tax-type').get('value')+"_"+Y.one('#who-type').get('value');
            var caze = Y.one('#overwrite-case').get('value');

            var cfg = {
                 method: 'GET',
                 on: {
                     complete: function(id, o) {

                         ov.hide();

                         var data = Y.JSON.parse(o.responseText);

                         if(data.error) {
                             this.fire('alert', {msg: "Beim Importieren Ihres Steuerfalls ist ein Fehler aufgetreten.<br><br>Bitte wenden Sie sich an den Support."});
                         }

                         this.get('config').lexofficeImportPresent = false;

                         instance.get('caseList').load();
                     }
                 },
                 data: 'import-file-type='+type+'&caze='+caze+'&form='+Y.one('#form-type').get('value'),
                 context: this
            };

            Y.io("/api/lexoffice/import", cfg);
        },

        _handleImportChoiceChange : function(e) {
            var value = Y.one("input[name=ui-import-file-type]:checked").get("value");

            Y.one('#ui-import-help-sst').setStyle('display', 'none');
            Y.one('#ui-import-help-taxman').setStyle('display', 'none');
            Y.one('#ui-import-help-elster').setStyle('display', 'none');

            if(value == "taxman") {
                Y.one('#ui-import-help-taxman').setStyle('display', 'block');
            }
            else if(value == "elsterformular") {
                Y.one('#ui-import-help-elster').setStyle('display', 'block');
            }
            else {
                Y.one('#ui-import-help-sst').setStyle('display', 'block');
            }
        },

        _handleImport: function(e, payload) {

            if( e.keyCode && 13 !== e.keyCode ) {
                return;
            }

            e.halt();

            if(Y.one('#ui-import-step2').getStyle('display') == 'none') {
                var value = Y.one("input[name=ui-import-file-type]:checked").get("value");
                Y.one('#import-file-type').set('value', value);
                Y.one('#ui-import-step1').setStyle('display', 'none');
                Y.one('#ui-import-desc-step1').setStyle('display', 'none');

                Y.one('#ui-import-step2').setStyle('display', 'block');
                Y.one('#ui-import-desc-step2').setStyle('display', 'block');

                Y.one('#import-case').setContent('Steuerfall importieren');

                if(value == "taxman") {
                    Y.one('#ui-import-casetype').setContent('Dateityp: Taxman oder Quicksteuer');
                }
                else if(value == "elsterformular") {
                    Y.one('#ui-import-casetype').setContent('Dateityp: Elsterformular');
                }
                else {
                    Y.one('#ui-import-casetype').setContent('Dateityp: smartsteuer');
                }

                return;
            }

            var instance = this,
                ov = payload.overlay,
                name = ov.get('contentBox').one('#importCaseName').get('value'),
                isTaxman = ov.get('contentBox').one('#import-file-type').get('value') == 'taxman',
                isDatev = ov.get('contentBox').one('#import-file-type').get('value').indexOf('datev') == 0,
                val;

            if(this._isInvalidName(name,ov)) {
                return;
            }

            val = ov.get('contentBox').one('#importFile').get('value');
            if(!val) {
                instance.showOverlayError(ov, CaseManager.ERROR_EMPTY_FILE );
                return;
            }

            if(isTaxman) {

                instance.setWaiting(ov);

                var cfg = {
                    method: 'POST',
                    on: {
                        complete: function(id, o) {

                            instance.removeWaiting(ov);

                            var data = Y.JSON.parse(o.responseText);

                            if(data.error) {
                                if(data.error == "FILE_MISSING") {
                                    this.fire('alert', {msg: "Bitte wählen Sie eine Datei aus!"});

                                    return;
                                }

                                if(data.error == "IMPORT_ERROR") {
                                    ov.hide();
                                    this.fire('alert', {msg: "Beim Importieren Ihres Steuerfalls ist ein Fehler aufgetreten.<br><br>Bitte achten Sie darauf, eine gültige Taxman-Datensicherung auszuwählen und versuchen Sie es ggfs. erneut."});
                                }
                                else if(data.error == "SELECT_CASE") {
                                    var options = "";

                                    Y.Array.each(data.caseList, function(it) {
                                        options += '<option value="'+it+'">'+it+'</option>';
                                    });

                                    Y.one('#ui-case-import-taxman-options-list').set('innerHTML', options);
                                    Y.one('#ui-case-import-taxman-options').setStyle('display', 'block');

                                    return;
                                }
                            }

                            Y.one('#ui-case-import-taxman-options').setStyle('display', 'none');

                            ov.hide();
                            instance.get('caseList').load();
                        }
                    },
                    form: {
                        id: 'ui-import-case-form',
                        upload: true
                    },
                    context: this
                };

                Y.io("dashboard/importTaxmanCase", cfg);
            }
            else if(isDatev) {
                instance.insertMockCase(new Y.Case({waiting:true,name:name}));
       	        ov.hide();

                var cfg = {
                    method: 'POST',
                    on: {
                        complete: function(id, o) {

                            var data = Y.JSON.parse(o.responseText);

                            if(data.error) {
                                if(data.error == "FILE_MISSING") {
                                    this.fire('alert', {msg: "Bitte wählen Sie eine Datei aus!"});

                                    return;
                                }
                                if(data.error == "IMPORT_ERROR") {
                                    ov.hide();
                                    this.fire('alert', {msg: "Beim Importieren Ihres Steuerfalls ist ein Fehler aufgetreten.<br><br>Bitte achten Sie darauf, eine gültige CSV-Datei im SKR04-Format auszuwählen und versuchen Sie es ggfs. erneut."});
                                }
                            }

                            instance.get('caseList').load();
                        }
                    },
                    form: {
                        id: 'ui-import-case-form',
                        upload: true
                    },
                    context: this
                };

                Y.io("dashboard/importDatevCase", cfg);
            }
            else {
                instance.insertMockCase(new Y.Case({waiting:true,name:name}));
       	        ov.hide();

       	        var errorMessage = "Beim Importieren Ihres Steuerfalls ist ein Fehler aufgetreten.<br><br>Bitte achten Sie darauf, eine valide .onse-Datei auszuwählen und versuchen Sie es ggfs. erneut.";

       	        //plugging elsterformular import here and autodetecting
                var isElsterformular = ov.get('contentBox').one('#import-file-type').get('value') == 'elsterformular';
                if(isElsterformular) {
                    errorMessage = "Beim Importieren Ihres Steuerfalls ist ein Fehler aufgetreten.<br><br>Bitte achten Sie darauf, eine valide .elfoexport-Datei auszuwählen und stellen Sie sicher dass Ihr Passwort korrekt ist.";

                    var pw = prompt("Bitte geben Sie Ihr beim Export gewähltes Passwort ein:");

                    if(pw) {
                        Y.one('#ui-import-case-password').set('value', pw);
                    }
                    else {
                        instance.get('caseList').load();

                        return;
                    }
                }

                var cfg = {
                    method: 'POST',
                    on: {
                        complete: function(id, o) {

                            var data = o.responseText;

                            if("FAIL" == data ) {
                                // generic error
                                this.fire('alert', {msg: errorMessage});
                            }
                            else if(data.indexOf("YEAR_CONVERTED_") >= 0 ) { //fmt: YEAR_CONVERTED_2009
                                // year is wrong
                                this.fire('alert', {msg: "Der zu importierende Fall stammt aus einem anderen Jahr und kann nur dort wieder importiert werden.<br><br>Um einen Fall aus dem Vorjahr zu übernehmen benutzen Sie bitte die Übertragen-Funktion des entsprechenden Steuerfalls."});
                            }
                            else if(data.indexOf("YEAR_SUPPRESSED_") >= 0 ) { //fmt: YEAR_SUPPRESSED_2009
                                // year is wrong, case existent in other year
                                this.fire('alert', {msg: "Der zu importierende Fall konnte nicht importiert werden da bereits ein Fall mit diesem Namen besteht.<br><br>Bitte wählen Sie einen anderen Namen oder löschen Sie den alten Fall.<br><br>Um einen Fall aus dem Vorjahr zu übernehmen benutzen Sie bitte die Übertragen-Funktion des entsprechenden Steuerfalls."});
                            }

                            instance.get('caseList').load();
                        }
                    },
                    form: {
                        id: 'ui-import-case-form',
                        upload: true
                    },
                    context: this
                };

                 Y.io("dashboard/importCase", cfg);
            }
        },

        // --- export case -----------

        _onExportCase : function(e) {
            var caze = e['case'];

            //TODO: put into CaseController when token issues are fixed
            window.location.href = this.get('config').secureURI("dashboard/export?id="+caze.get('id'));
        },

        // --- lock/unlock ----------------------------------------------------------------------------------------

        _onLockCase : function(e) {
            var config = this.get('config'),
                overlay = this.showOverlay('lock', {'case':e['case']},
		                {'tokenName':config.tName, 'token':config.t, 'id':e['case'].get('id')});
            // select the default value for easy editing.
            Y.Node.getDOMNode( overlay.get('contentBox').one('#pass1') ).select();
        },

        _handleLock: function(e, payload) {

             if( e.keyCode && 13 !== e.keyCode ) {
                 return;
             }

             e.halt();

             var instance = this,
                 ov = payload.overlay,
                 pw1 = Y.one('#pass1').get('value'),
                 pw2 = Y.one('#pass2').get('value'),
		         caze = payload['case'];

             if(pw1 != pw2) {
                instance.showOverlayError(ov, 'Die angegebenen Kennwörter stimmen nicht überein.');
                return;
             }

             if(pw1 != pw1.replace(/[^A-Za-z 0-9 \.,\?""!@#\$%\^&\*\(\)-_=\+;:<>\/\\\|\}\{\[\]`~]*/g, '')) {
                instance.showOverlayError(ov, 'Ihr Kennwort enthält nicht erlaubte Zeichen.');
                return;
             }

             if(pw1 != "" && !instance.checkPasswordStrength(pw1)) {
                instance.showOverlayError(ov, 'Bitte geben Sie ein Kennwort mit mindestens 10 Zeichen inklusive eines Sonderzeichens an.');
                return;
             }

             Y.one('#pass1').set('value',pw1);
             Y.one('#pass2').set('value',pw2);

	         instance._indicateWaiting(caze);
             ov.hide();

             var cfg = {
               on: { success: function(id, o) {
                       if(o.responseText == 'OK') {
                           this.get('caseList').load();
                       }
                       else {
                           this.fire('problem', {msg: o.responseText});
                       }
                   },
                   failure: function(id, o) {
                       this.fire('problem', {msg: o.responseText});
                   },
	               complete : function() {
		               instance._clearWaitingState(caze);
	               }
               },
               context: this,
               method: 'POST',
               form: {
                       id: 'password-form'
               }
            };

            Y.io("dashboard/lock", cfg);
         },

        checkPasswordStrength : function(pw) {
            var iChars = "!@#$%^&*()+=-[]\\';,./{}|\":<>?";

            if(!pw || pw.length < 10) {
                return false;
            }

            for (var i = 0; i < pw.length; i++) {
                if (iChars.indexOf(pw.charAt(i)) != -1) {
                    return true;
                }
            }

            return false;
        },

        _onUnlockCase : function(e) {
             var config = this.get('config'),
		         caze = e['case'],
                 overlay;

	         overlay = this.showOverlay('unlock', {'case':caze}, {'tokenName':config.tName, 'token':config.t, 'id':caze.get('id')});
             // select the default value for easy editing.
             Y.Node.getDOMNode( overlay.get('contentBox').one('#password') ).select();
         },

	    _handleUnlock:function (e, payload) {
		    var that = this,
				cfg, caze, ov;

		    if (e.keyCode && 13 !== e.keyCode) {
			    return;
		    }
		    e.halt();
		    caze = payload['case'];
		    ov = payload.overlay;

		    that._indicateWaiting(caze);
		    ov.hide();
		    cfg = {
			    on:{
				    success:function () {
					    caze.setAttrs({'locked': false,'active': true,'wasLocked': true});
				    },
				    failure:function (id, o) {
					    Y.later(300,that,function(){that.fire('problem', {msg:'Das angegebene Kennwort ist fehlerhaft!'});});
				    },
				    complete:function(){
					    that._clearWaitingState(caze);
				    }
			    },
			    method:'POST',
			    context:this,
			    form:{
				    id:'password-provide-form'
			    }
		    };

		    Y.io('api/case/unlock', cfg);
	    },

        _onVastCase : function(e) {
            alert('showOverlay');
            overlay = this.showOverlay('vast');
        },

	    // --- case management helper -----------------------------------------------------------------------------

        _isInvalidName :function(name, overlay) {
            var error = this.checkName(name);

            if( error ) {
                this.showOverlayError(overlay,error);
                return true;
            }
            return false;

        },

        checkName: function(name) {
            return this._isEmpty(name) || this._isInvalid(name) || this._isUnique(name) || this._isTooLong(name);
        },

        _isEmpty: function(name) {
            if(null == name || 0 == Y.Lang.trim(name).length) {
                return CaseManager.ERROR_EMPTY_NAME;
            }
            // no return here hence it will be undefined.
        },

        _isInvalid: function(name) {
            if(null != name && (
                    -1 < name.indexOf('>') ||
                    -1 < name.indexOf('<') ||
                    -1 < name.indexOf('$') ||
                    -1 < name.indexOf('"'))) {
                return CaseManager.ERROR_NAME_INVALID;
            }
            // no return here hence it will be undefined.
        },

        //TODO: only has to be unique in a single year
        _isUnique: function(name) {
            var list = this.get('caseList');
            if( 0 < list.filter(function(model){ return name === model.get('name')}).length ) {
                return Y.Lang.sub(CaseManager.ERROR_NAME_EXSITS, {name:name});
            }
            // no return here hence it will be undefined.
        },

        _isTooLong: function(name) {
            if(Y.Lang.trim(name).length > 200) {
                return CaseManager.ERROR_NAME_TOO_LONG;
            }
            // no return here hence it will be undefined.
        },

        migrateCase: function(caze, name, pass) {
            var appendix = '';

            if(caze.get('locked')) {
                appendix = '&password='+encodeURIComponent(pass);
            }
            if(name) {
                appendix += '&name='+encodeURIComponent(name);
            }

            var cfg = {
                on: {
                    success: function(id, o) {
                        var json = Y.JSON.parse(o.responseText);

                        if(json.status == 'DECRYPTION') {
                            this.fire('problem', {msg : 'Der Steuerfall konnte mit dem angegebenen Passwort nicht entschlüsselt werden!'});
                        }
                        else {
                            this.render();
                            this.showOverlay('imported', {'id': json.id});
                        }
                    },
                    failure: function(id, o) {
                        this.fire('problem', {msg : 'Der Steuerfall konnte nicht übertragen werden!'});
                    }
                },
                data : 'id='+caze.get('id')+appendix,
                context: this
            };

            Y.io('dashboard/migrateCase', cfg);
        },

        _handleCustomMigrate: function(e) {
              var instance = this,
                  overlay = instance._migrateOverlay,
                  name = overlay.get('contentBox').one('#caseName').get('value');

              if (e.keyCode && 13 !== e.keyCode) {
      			  return;
      		  }

              e.halt();

              var err = instance.checkName(name)
              if(err) {
                instance.showOverlayError(overlay, err);
                return;
              }

              overlay.hide();

              if(this._migrateCase.get('locked')) {
                  instance._migrateName = name;
                  instance._migratePasswordOverlay = this.showOverlay('password');
              }
              else {
                instance.migrateCase(this._migrateCase, name);
              }
          },

        _handleCustomMigratePassword : function(e) {
            var instance = this;

            if (e.keyCode && 13 !== e.keyCode) {
    			  return;
    		}

            var pw = Y.one('#password-migrate').get('value');
            instance._migratePasswordOverlay.hide();

            this.migrateCase(this._migrateCase, instance._migrateName, pw);
        },


        _onMigrateCase: function(e) {
              var instance = this,
                  config = this.get('config');

              if(e) e.halt();

              this._migrateCase = e['case'];

              this._migrateOverlay = this.showOverlay('migrate', null, {name: this._migrateCase.get('name')});
        },

	    _onEditLegacyCase : function(e) {

            if(e['case'].get('openURL')) {
                Y.config.win.location.href =
                    e['case'].get('openURL')+"/autoLogin?path="+
                    encodeURI("/app%23/case/"+e['case'].get('id')+"/interview");
            }
            else {
                // double check the users intent...
                this.showOverlay('leave', {'case': e['case']});
            }
		},

	    _handleLeaveApp : function(e, payload) {
		    var url;
		    if (e.keyCode && 13 !== e.keyCode) {
			    return;
		    }
		    e.halt();

		    Y.config.win.location.href = Y._config.switchYearUrl;
	    },

        _handleOpenVast : function() {
            var win = window.open(this.get('config').vastURL, '_blank');
            win.focus();
        },

        _handleOpenBelegeService : function() {
            var win = window.open(this.get('config').beleguanURL, '_blank');
            win.focus();
        }

    },{

        ERROR_NAME_EXSITS  : 'Es existiert bereits ein Steuerfall mit dem Namen "{name}". ' +
                'Bitte geben Sie eine eindeutige Bezeichnung an.',
        ERROR_NAME_INVALID : 'Die Zeichen &gt;, &lt;, &dollar; und &quot; können nicht verwendet werden.',
        ERROR_EMPTY_NAME   : 'Bitte geben Sie einen Namen an.',
        ERROR_EMPTY_FILE   : 'Sie haben noch keine Datei für den Import ausgewählt.',
        ERROR_NAME_TOO_LONG : 'Der von Ihnen gew&auml;hlte Name ist zu lang',

        ATTRS: {
            // configuration of the whole app.
            config : {},
            // models and modelLists which are managed by this app.
            caseList : {value : new Y.CaseList()},
            caseLegacyList : {value : new Y.CaseLegacyList()},

            // As the case manager is an app itself we have to explicitly set its container. If we don't, it
            // will fallback to use 'body'.
            container: {
                valueFn: function () {
                    return Y.Node.create('<div id="cases-manager"/>');
                }
            },
            viewContainer : {
                valueFn: function () {
                    return Y.Node.create('<div id="cases-manager-body"/>');
                }
            },

            // we don't want this app to do any routing accidentally.
            // Therefor we are disabling the link selector so that it will never hit.
            linkSelector : {
                value: ''
            }
        }
    });

    // add it to the namespace
    Y.namespace("smst").CaseManager = CaseManager;

}, '1.0.0', {
    requires:[
        "app",
        "app-header",
        'app-overlays',
        'base-build',
        "case-manager-views",
        "case-models",
        "event",
        "smart-handlebars-templates",
        "handlebars-view",
        'node-event-simulate',
        'model-list',
        "smst-lang",
        "smst-overlays"
    ]
});
